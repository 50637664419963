import Logo from 'assets/svg/logo-ralali-big/logo-ralali-big.svg'
import LayoutAuth from 'common/components/LayoutAuth'
import theme from 'common/theme'
import MediaHelper from 'lib/media-helper'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import LoginForm from 'services/Auth/components/LoginForm'
import OtpFlowModal from 'services/Auth/components/OtpFlowModal'
import { useStoreActions, useStoreState } from 'stores/hooks'
import styled from 'styled-components'

const WrapperLogo = styled.a`
  display: flex;
  justify-content: center;
  z-index: ${theme.zIndex.dropdown};
  margin-top: 40px;
  text-decoration: none;
  cursor: pointer;

  ${MediaHelper('laptop')`svg {display: none;}`}
`

const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
function MetaData(): JSX.Element {
  return (
    <Head>
      <title>Masuk / Login | Ralali.com</title>
      <meta
        name="description"
        content="Segera login ke akun Ralali untuk menikmati kemudahan belanja kebutuhan bisnis Anda. Login mudah dengan no telp, email atau akun sosial media Anda."
      />
      <meta name="keywords" content="login ralali" />
    </Head>
  )
}

function LoginV2({ lang }: { lang: string }): JSX.Element {
  const router = useRouter()

  const [showModal, setShowModal] = useState<boolean>(false)

  const {
    login: { step, isLoading },
  } = useStoreState((state) => state)

  const {
    setStep,
    postVerifyOtp,
    postSendOtp,
    postResendOtp,
  } = useStoreActions((actions) => actions.login)

  const { setReferralCode } = useStoreActions(
    (actions) => actions.register,
  )

  useEffect(() => {
    if (step === 'login-form') {
      setShowModal(false)
    } else if (step === 'otp-channel' || step === 'otp-form') {
      setShowModal(true)
    }
  }, [step])

  const handleCloseLogin = useCallback(() => {
    if (step === 'otp-form') return
    setStep('login-form')
  }, [setStep, step])

  useEffect(() => {
    if (router.query.referral) {
      setReferralCode(router.query.referral)
    }
  }, [router.query.referral, setReferralCode, step])

  return (
    <LayoutAuth lang={lang} metaData={<MetaData />}>
      <WrapperLogo href="/marketplace">
        <Logo />
      </WrapperLogo>
      <FormWrapper>
        <LoginForm responsive inputWidth="100%" />
      </FormWrapper>
      <OtpFlowModal
        isOpen={showModal}
        isLoading={isLoading}
        handleClose={handleCloseLogin}
        step={step}
        handleSetStep={setStep}
        handlePostResendOtp={postResendOtp}
        handlePostSendOtp={postSendOtp}
        handlePostVerifyOtp={postVerifyOtp}
        responsive
      />
    </LayoutAuth>
  )
}

export default LoginV2
